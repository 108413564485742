<template>
  <v-expansion-panels  accordion multiple v-model="panels" focusable :readonly="$vuetify.breakpoint.mdAndUp">
     <v-expansion-panel class="mb-3" v-for="(item,index) in items" :key="index" @change="posicion(item.ref)" :id="item.ref" >
      <v-expansion-panel-header >
        <div class="subtitle-1 font-weight-medium primary--text">{{item.titulo}}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-3">
        <component  :is="item.componente"></component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>


export default {
  components: {
    'InfoFormacion':()=>import("@/components/Formacion")
  },
  data() {
    return {
      panels: [],
      maxItems:this.$vuetify.breakpoint.mdAndUp?2:1,
      items:[
        {
          titulo:'1.- Registro Formaciones Academicas',
          componente:'InfoFormacion',
          ref:"panelFormaciones"
        }
      ]
    };
  },
   mounted(){
    this.iniciarPanel()
  },
  methods:{
    iniciarPanel(){
       var i=0;
       var array=[];
       for (let index = 0; index < this.maxItems; index++) {
         array.push(i);
         i++;
       } 
       this.panels=array;
    },
     posicion(data){
      if(data!=null){
        this.$vuetify.goTo(`#${data}`)
      }
    }
  }
  
};
</script>